import React from "react"
import Layout from "../components/layout"
import { FooterAlternate1 } from "../components/footer"
import SEO from "../components/seo"

const IndexPage = () => (
    <Layout>
      <SEO title="PageTitle" />

      Hello World
      
      <FooterAlternate1 />

    </Layout>
)

export default IndexPage 